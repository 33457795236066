import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: "/Login",

  },
  {
    path: '/Login',
    name: 'Login',
    
    component: () => import('../views/Login.vue')
  },
  {
    path: '/SideBar',
    name: 'SideBar',
    component: () => import('../views/SideBar.vue')
  },
  {
    path: '/HomePage',
    name: 'HomePage',
    component: () => import('../views/HomePage.vue'),
    children: [
      {
        path: "/AddManager",
        component: () => import("../views/GameManagerViews/AddManager.vue"),
      },
      {
        path: "/FindPlayerIDByName",
        component: () => import("../views/GameManagerViews/FindPlayerIDByName.vue"),
      },
      {
        path: "/AddRes",
        component: () => import("../views/GameManagerViews/AddRes.vue"),
      },
      {
        path: "/AddSkinByOpenID",
        component: () => import("../views/GameManagerViews/AddSkinByOpenID.vue"),
      },
      {
        path: "/AddSkinByAccountID",
        component: () => import("../views/GameManagerViews/AddSkinByAccountID.vue"),
      },
      {
        path: '/AddCarousel',
        name: 'AddCarousel',
        component: () => import('../views/GameManagerViews/AddCarousel.vue')
      },
      {
        path: '/TotalStatisticsByDay',
        name: 'TotalStatisticsByDay',
        component: () => import('../views/GameManagerViews/TotalStatisticsByDay.vue')
      },
      {
        path: '/TotalStatisticsByHour',
        name: 'TotalStatisticsByHour',
        component: () => import('../views/GameManagerViews/TotalStatisticsByHour.vue')
      },
      {
        path: '/AllAnchorDailyIncome',
        name: 'AllAnchorDailyIncome',
        component: () => import('../views/GameManagerViews/AllAnchorDailyIncome.vue')
      },
      {
        path: '/AnchorDailyIncome',
        name: 'AnchorDailyIncome',
        component: () => import('../views/GameManagerViews/AnchorDailyIncome.vue')
      },
      {
        path: '/TwoDayCompare',
        name: 'TwoDayCompare',
        component: () => import('../views/GameManagerViews/TwoDayCompare.vue')
      },
      {
        path: '/ShowPlayerAchievementsByID',
        name: 'ShowPlayerAchievementsByID',
        component: () => import('../views/GameManagerViews/ShowPlayerAchievementsByID.vue')
      },
      {
        path: '/AnchorNumHourly',
        name: 'AnchorNumHourly',
        component: () => import('../views/GameManagerViews/AnchorNumHourly.vue')
      },
      {
        path: '/AnchorNumWeekly',
        name: 'AnchorNumWeekly',
        component: () => import('../views/GameManagerViews/AnchorNumWeekly.vue')
      },
      {
        path: '/ShowOperations',
        name: 'ShowOperations',
        component: () => import('../views/GameManagerViews/ShowOperations.vue')
      },
      {
        path: '/StatisticsModeIncome',
        name: 'StatisticsModeIncome',
        component: () => import('../views/GameManagerViews/StatisticsModeIncome.vue')
      },
      {
        path: '/MultipleSkinDistribution',
        name: 'MultipleSkinDistribution',
        component: () => import('../views/GameManagerViews/MultipleSkinDistribution.vue')
      },
      {
        path: '/QueryPaidByOpenID',
        name: 'QueryPaidByOpenID',
        component: () => import('../views/GameManagerViews/QueryPaidByOpenID.vue')
      },
      {
        path: '/MultipleSkinDistributionByIDs',
        name: 'MultipleSkinDistributionByIDs',
        component: () => import('../views/GameManagerViews/MultipleSkinDistributionByIDs.vue')
      },
      {
        path: '/AllUserGourdOperations',
        name: 'AllUserGourdOperations',
        component: () => import('../views/GameManagerViews/AllUserGourdOperations.vue')
      }]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
