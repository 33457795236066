import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'

import axios from 'axios'
import apiServe from '@/api'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'

const app = createApp(App).use(store).use(router)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
import VChart from "vue-echarts";
app.component("v-chart",VChart)
import * as echarts from "echarts"
app.config.globalProperties.$echarts = echarts
import SideBar from './views/SideBar.vue'
app.component("SideBar",SideBar)
app.use(ElementPlus, {
    locale: zhCn,
  })
app.mount('#app')

app.config.globalProperties.$http = apiServe
app.config.globalProperties.$axios = axios
