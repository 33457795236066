import http from '../utils/requestUtil'

 
export default {
  /**
   * 根据用户名、密码登录
   */
  LoginWithUserName(data: any) {
    return http.post(
      '/gm_server/login.php',
      data,)},
 /**
   * 根据用户名、密码添加用户信息
   */
  AddManager(data: any) {
  return http.post(
    '/gm_server/register.php',
    data,)},
    /**
   * 获取平台信息
   */
  GetPlatForm() {
    return http.post(
      '/gm_server/getPlatform.php',)},
    /**
   * 根据玩家名查询用户信息
   */
  FindPlayerIDByName(data: any) {
    return http.post(
      '/gm_server/callGameServer/findPlayerIDByName.php',
      data,)},
    /**
   * 根据玩家ID发放葫芦
   */
    AddRes(data: any) {
      return http.post(
        '/gm_server/callGameServer/addRes.php',
        data,)},
    /**
   * 根据玩家ID发放皮肤
   */
    AddSkinByOpenID(data: any) {
      return http.post(
        '/gm_server/callGameServer/addSkinByOpenID.php',
        data,)},
    /**
   * 根据快手ID发放皮肤  快手特有
   */
    AddSkinByAccountID(data: any) {
      return http.post(
        '/gm_server/callGameServer/addSkinByAccount.php',
        data,)},
    /**
   * 根据快手ID发放皮肤  快手特有
   */
    AddCarousel(data: any) {
      return http.post(
        '/gm_server/callGameServer/carousel.php',
        data,)},
    /**
   * 日总数据 拥有数据 开播主播数量 付费用户数量 礼物总数与类型
   */
    TotalStatisticsByDay(data: any) {
      return http.post(
        '/gm_server/statisticsFormSQL/totalStatisticsByDay.php',
        data,)},
    /**
   * 玩家数量 传入参数：日期 平台
   */
    PlayerCountDaily(data: any) {
      return http.post(
        '/gm_server/statisticsFormSQL/playerCountDaily.php',
        data,)},
    TotalStatisticsByHour(data: any) {
      return http.post(
        '/gm_server/statisticsFormSQL/totalStatisticsByHour.php',
        data,)},
    AllAnchorDailyIncome(data: any) {
      return http.post(
        '/gm_server/statisticsFormSQL/allAnchorDailyIncome.php',
        data,)},
    AnchorDailyIncome(data: any) {
      return http.post(
        '/gm_server/statisticsFormSQL/anchorDailyIncome.php',
        data,)},
    CompareDayGiftData(data: any) {
      return http.post(
        '/gm_server/statisticsFormSQL/compareDayGiftData.php',
        data,)},
    ShowPlayerAchievementsByID(data: any) {
      return http.post(
        '/gm_server/statisticsFormSQL/showPlayerAchievementsByID.php',
        data,)},
    AnchorNumDeltaTime(data: any) {
      return http.post(
        '/gm_server/statisticsFormSQL/anchorNumDeltaTime.php',
        data,)},
    QueryAllManager(data: any) {
      return http.post(
        '/gm_server/queryAllManager.php',
        data,)},
    GetPositions(data: any) {
      return http.post(
        '/gm_server/getPositions.php',
        data,)},
    DeleteManager(data: any) {
      return http.post(
        '/gm_server/deleteManager.php',
        data,)},
    ModifyManager(data: any) {
      return http.post(
        '/gm_server/modifyManager.php',
        data,)},
    ValidateLogin(data: any) {
      return http.post(
        '/gm_server/validateLogin.php',
        data,)},
    GetOperations(data: any) {
      return http.post(
        '/gm_server/getOperations.php',
        data,)},
    GetSelfPosition(data: any) {
      return http.post(
        '/gm_server/getSelfPosition.php',
        data,)},    
    GetModeIncome(data: any) {
      return http.post(
        '/gm_server/statisticsFormSQL/getModeIncome.php',
        data,)}, 
    GetExchangeRates(data: any) {
      return http.post(
        '/gm_server/getExchangeRates.php',
        data,)},
    QueryPaidByOpenID(data: any) {
      return http.post('/gm_server/statisticsFormSQL/queryPaidByOpenID.php',data)
    },
    AllUserGourdOperations(data: any) {
      return http.post('/gm_server/statisticsFormSQL/allUserGourdOperations.php',data)
    },
}
